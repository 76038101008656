'use client';

import type { OffcanvasCartProps } from '.';

import { Fragment, useEffect, useState } from 'react';
import { useCartContext } from "@context/cart-provider"

import Button from '@components/atoms/button';
import Image from '@components/atoms/image';
import FormControl from '@components/atoms/form-control';
import Link from '@components/atoms/link';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import { useSession } from 'next-auth/react';
import formatPrice from "@utils/format-price";
import { useLocale } from 'next-intl';
import RemoveFromCartButton from '../remove-from-cart';
import { useRouter } from 'next/navigation';
import classNames from 'classnames';
import { handleAddPromotionToCart, handleUpdateItemFromCart } from '@app/actions';
import { toast } from 'react-toastify';
import Errors from '@/components/organisms/cart/errors';
import FreeShipping from '@/components/organisms/cart/free-shipping';
import t from '@/utils/translation-faker';
import { NewCustomerCouponInfo, default as NewCustomerCoupon } from '@/components/atoms/new-customer-coupon';
import ActionBox from '@/components/atoms/action-box';
import googleSendEvent from '@helpers/google-send-event';

const LineItemQty = ({ product, disabled = false }: { product: any, disabled?: boolean }) => {
    const [value, setValue] = useState<string>('0')
    const [lineItem, setLineItem] = useState<{ id: string, quantity: number, label: string, payload: { productNumber: string, purchaseUnit: number }, priceDefinition: { referencePriceDefinition: { purchaseUnit: number } } }>()

    const { cart, setCart } = useCartContext()
    const locale = useLocale()

    useEffect(() => {
        const lineItem = cart?.lineItems?.find(({ id }: { id: string }) => id === product.id)
        if (lineItem) setLineItem(lineItem)
    }, [cart, product.id])

    useEffect(() => {
        if (undefined === lineItem) return
        setValue(() => (lineItem.quantity * (lineItem.payload.purchaseUnit ?? lineItem.priceDefinition?.referencePriceDefinition?.purchaseUnit ?? 1)).toString())
    }, [lineItem])

    const updateItemInCart = async (product: any, quantity: number) => {
        try {
            if (lineItem) {
                var formData = new FormData()
                formData.set('id', product.id)
                formData.set('referencedId', product.referencedId)
                formData.set('qty', quantity.toString())

                await handleUpdateItemFromCart(formData).then((cart) => {
                    setCart(cart)

                    const _lineItem = cart?.lineItems.find(({ id }: { id: string }) => lineItem.id === id)
                    if (_lineItem) {

                        googleSendEvent(lineItem.quantity < quantity ? 'add_to_cart' : 'remove_from_cart', {
                            value: (_lineItem.priceDefinition.price / (_lineItem.payload.purchaseUnit ?? _lineItem.priceDefinition?.referencePriceDefinition?.purchaseUnit ?? 1)),
                            currency: 'de-CH' === locale ? 'CHF' : 'EUR',
                            items: [{
                                item_id: _lineItem.payload.productNumber,
                                item_name: _lineItem.label,
                                quantity: (lineItem.quantity < quantity ? quantity - lineItem.quantity : lineItem.quantity - quantity) * (_lineItem.payload.purchaseUnit ?? _lineItem.priceDefinition?.referencePriceDefinition?.purchaseUnit ?? 1)
                            }]
                        })

                    }
                })
            }
        } catch (err: any) {
            toast.error(err);
        }
    };

    const checkAmount = (amount: number, purchaseUnit: number, maxPurchase: number) => {
        if (0 !== amount % purchaseUnit) {
            toast.info('Ihre eingegebene Menge wird auf die nächstmögliche Bestellmenge angepasst.')
            return Math.ceil(amount / purchaseUnit)
        }
        if (maxPurchase && (amount) > maxPurchase) {
            toast.info('Ihre eingegebene Menge überschreitet die maximale Bestellmenge und wird herabgesetzt.')
            return Math.floor(maxPurchase)
        }
        return amount / purchaseUnit
    }

    return (
        <div className="d-flex justify-content-end border m-0 p-0 col">
            <Button
                className={'icon  icon--minus  border-0'}
                onClick={() => updateItemInCart(product, product.quantity - product.quantityInformation.purchaseSteps)}
                variant={'outline-secondary'}
                disabled={disabled}
            />
            <FormControl
                className={'amount-form-control  text-center  fw-bold  border-0  bg-white'}
                type={'number'}
                value={value}
                onChange={(e) => setValue(e.currentTarget.value)}
                onBlur={(e) => {
                    if ('' !== e.target.value && !isNaN(Number(e.target.value))) {
                        const purchaseUnit = (product.payload.purchaseUnit ?? product.priceDefinition?.referencePriceDefinition?.purchaseUnit ?? 1)
                        updateItemInCart(product, checkAmount(Number(e.target.value), purchaseUnit, product.payload.maxPurchase))
                    }
                }}
                disabled={disabled}
            />
            <Button
                className={'icon  icon--plus  border-0'}
                onClick={() => updateItemInCart(product, product.quantity + product.quantityInformation.purchaseSteps)}
                variant="outline-secondary"
                disabled={(!!product.payload.maxPurchase && product.quantity >= product.payload.maxPurchase) || disabled}
            />
        </div>
    )
}


const OffcanvasCart = ({ label: { cart: cartLabel, product: productLabel, products: productsLabel, button: buttonLabel, continueShopping, ...label } }: OffcanvasCartProps) => {
    const { data: session }: any = useSession()

    const locale = useLocale()
    const router = useRouter()

    const { cart, loading, setLoading, setCart, showOffcanvas: show, setShowOffcanvas: setShow } = useCartContext()

    const [promotion, setPromotion] = useState<string>('')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const addPromotionToCart = async (code: string) => {
        await handleAddPromotionToCart([code]).then((cart) => {
            setCart(cart)
            setLoading(false)
        });
        setPromotion('')
    }

    return (
        <>
            <Button icon={'cart'} onClick={handleShow} variant={'transparent'} aria-label={buttonLabel} className={'position-relative  p-0  m-0  d-inline-flex  flex-column'}>
                <span className={'d-none  d-md-block  fs-10  fw-extrabold  text-uppercase'}>{buttonLabel}</span>
                {0 < cart?.lineItems?.length && (
                    <span className={'position-absolute  d-inline-block  top-0  start-100  start-md-auto  end-md-0  translate-middle  rounded-circle  bg-success  button--active'} />
                )}
            </Button>
            <Offcanvas show={show} onHide={handleClose} placement={'end'}>
                <Offcanvas.Header className={'py-4 bg-gray-200'}>
                    <Offcanvas.Title>
                        <span onClick={handleClose} className={'d-inline-flex  fw-bold  fs-6 icon  icon--chevron-left  icon--size__24  icon--valign__middle  icon--secondary  text-decoration-none  cursor--pointer'}>
                            {continueShopping}
                        </span>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className={'flex-grow  px-0'}>
                    {loading ? (
                        <div className={'h-100 my-5 d-flex justify-content-center align-items-center'}>
                            <div className={'h-100 d-flex flex-column justify-content-center align-items-center'}>
                                <Spinner animation={'border'} variant={'secondary'} />
                                <span className={'mt-2'}>{('Lädt ...')}</span>
                            </div>
                        </div>
                    ) : (
                        <Container className={'px-3'}>
                            <Container className={'Paragraph-Lead-Default'}>
                                <Row>
                                    <Col className={'ps-0  fw-bold'}>{cartLabel}</Col>
                                    {0 < cart?.lineItems?.length && (
                                        <Col className={'d-inline-flex  justify-content-end  pe-0'}>
                                            <p className={'Paragraph-Lead-Muted'}>{cart.lineItems.length} {t(1 === cart.lineItems.length ? productLabel : productsLabel)}</p>
                                        </Col>
                                    )}
                                </Row>
                            </Container>
                            {0 < cart?.lineItems?.length ? (
                                <ListGroup className="offcanvas-shopping-cart-listgroup" variant="flush">
                                    <NewCustomerCoupon />
                                    <FreeShipping />
                                    {cart.lineItems.map((product: any, index: number) => (
                                        <ListGroupItem key={`sideCart_${product.id}_${index}`} className={classNames('px-0  py-2', { 'border-bottom': index < cart.lineItems.length - 1 })}>
                                            <div className={'d-flex  gap-4  align-items-start  py-2  px-0  container'}>
                                                {(['product', 'customized-products'].includes(product.type) && product.payload.seoUrls?.[0]?.seoPathInfo) ? (
                                                    <Link href={product.payload.seoUrls?.[0]?.seoPathInfo} onClick={() => setShow(false)}>
                                                        <Image
                                                            className={'cart-preview-image  object-fit-contain  border  border-gray-400'}
                                                            src={product.cover?.url ?? ('promotion' === product.type ? '/rabatt.png' : '/no-image.png')}
                                                            alt={product.cover?.alt ?? product.label}
                                                            title={product.cover?.title ?? product.label}
                                                            width={75}
                                                            height={75}
                                                        />
                                                    </Link>
                                                ) : (
                                                    <Image
                                                        className={'cart-preview-image  object-fit-contain  border  border-gray-400'}
                                                        src={product.cover?.url ?? ('promotion' === product.type ? '/rabatt.png' : '/no-image.png')}
                                                        alt={product.cover?.alt ?? product.label}
                                                        title={product.cover?.title ?? product.label}
                                                        width={75}
                                                        height={75}
                                                    />
                                                )}
                                                <div className={'flex-grow-1'}>
                                                    {['product', 'customized-products'].includes(product.type) ? (
                                                        product.payload.seoUrls?.[0]?.seoPathInfo ? (
                                                            <Link href={product.payload.seoUrls?.[0]?.seoPathInfo} className={'text-nowrap  fs-8  fw-bold  p-0  mb-0  text-decoration-none'} onClick={() => setShow(false)}>
                                                                {product.label}
                                                            </Link>
                                                        ) : (
                                                            <span className={'fw-bold'}>
                                                                {product.label}
                                                            </span>
                                                        )
                                                    ) : (
                                                        <span className={'text-nowrap  fs-8  fw-bold  p-0  mb-0'}>
                                                            {product.label}
                                                        </span>
                                                    )}
                                                    {product.payload.options && (
                                                        <p className={'Paragraph-Default-Muted  fs-8'}>
                                                            {product.payload.options.map(({ group, option }: { group: string, option: string }) => `${group}: ${option}`).join(', ')}
                                                        </p>
                                                    )}
                                                    {0 < product.children?.length && (
                                                        <p className={'Paragraph-Default-Muted  fs-8'}>
                                                            {product
                                                                .children
                                                                ?.filter(({ type, children }: { type: string, children: { label: string }[] }) => 'product' !== type && children?.length)
                                                                .map(({ label, children }: { label: string, children: { label: string }[] }) => (
                                                                    `${label}: ${children.reduce((acc: any, cur: any) => {
                                                                        acc.push(cur.label)
                                                                        return acc
                                                                    }, []).join(', ')}`
                                                                )).join(', ')}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="d-flex justify-content-end align-items-center flex-grow-0">
                                                    {(product.removable && !(process.env.NEXT_PUBLIC_PROMOTION_NOT_REMOVABLE?.split(',')?.includes(product?.payload?.promotionId))) && (
                                                        <RemoveFromCartButton referenceId={product.id} productName={product.label} />
                                                    )}
                                                </div>
                                            </div>
                                            <div className={'mb-0 pb-2 row'} style={{ paddingLeft: 12, paddingRight: 12 }}>
                                                {['product', 'customized-products'].includes(product.type) && 0 != product.price.totalPrice && <LineItemQty product={product} disabled={'customized-products' === product.type} />}
                                                <div className="d-flex justify-content-end col">
                                                    <span className="product-price fw-bold">
                                                        {0 !== product.price.totalPrice ? (
                                                            product.price.calculatedTaxes?.map(({ price, tax }: { price: number, tax: number }, index: number) => (
                                                                <Fragment key={product.id + index}>
                                                                    {formatPrice({ value: price + (true === session?.user?.anonym && true === session?.user?.group?.displayGross ? tax : 0), locale, currencyId: 'de-CH' === locale ? 'CHF' : 'EUR' })}
                                                                </Fragment>
                                                            ))
                                                        ) : (
                                                            t('GRATIS')
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            ) : (
                                <>
                                    <div className={'mt-3  fs-8'}>Ihr Warenkorb ist leer.</div>
                                    <Errors />
                                </>
                            )}
                        </Container>
                    )}
                </Offcanvas.Body>
                <Container className={'offcanvas-footer  border-top'}>
                    {0 < cart?.lineItems?.length && (
                        <>
                            <Row className="offcanvas-row-bottom  my-3 px-3">
                                <Col className={'px-0'} style={{ background: 'gray' }}>
                                    <div className={'coupon-field  input-group'}>
                                        <FormControl id={'coupon'}
                                            value={promotion}
                                            onChange={(e: any) => setPromotion(e.currentTarget.value.toString())}
                                            onKeyPress={(e: any) => {
                                                if (e.key === "Enter") {
                                                    addPromotionToCart(promotion)
                                                }
                                            }}
                                            placeholder={t('coupon_code', { ns: 'common' })}
                                        />
                                        <Button
                                            className={'icon  icon--check  icon--white  bg-secondary'}
                                            onClick={() => addPromotionToCart(promotion)}
                                            variant={'outline-light'}
                                            type={'submit'}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="offcanvas-row-bottom fs-8 px-3">
                                <hr className={'mt-0'} />
                                <Col className={'px-0 fw-semibold'}>Zwischensumme</Col>
                                <Col className="d-flex justify-content-end fw-bold">{formatPrice({ value: cart?.price?.positionPrice + ((true === session?.user?.anonym && true === session?.user?.group?.displayGross ? cart?.price?.taxRules.taxRate : 0) / 100 * cart?.price?.positionPrice), locale, currencyId: 'de-CH' === locale ? 'CHF' : 'EUR' })}</Col>
                            </Row>

                            {cart?.deliveries?.map((delivery: any) =>
                                <Row className="mb-3 offcanvas-row-bottom fs-8 px-3" key={'sidecart_delivery_' + delivery.id}>
                                    <Col className={'px-0  fw-semibold'}>
                                        <Link href={'/zahlung-und-versand/lieferinformationen'} onClick={() => setShow(false)} className={''}>{`Versandkosten`}</Link>
                                    </Col>
                                    <Col className="d-flex justify-content-end fw-bold">{formatPrice({ value: delivery.shippingCosts.totalPrice + ((true === session?.user?.anonym && true === session?.user?.group?.displayGross ? cart?.price?.taxRules.taxRate : 0) / 100 * delivery.shippingCosts.totalPrice), locale, currencyId: 'de-CH' === locale ? 'CHF' : 'EUR' })}</Col>
                                </Row>
                            )}

                            {cart?.price.calculatedTaxes.map((tax: any, index: number) =>
                                <Row key={'sidecart_tax_' + index} className="mb-3 offcanvas-row-bottom fs-8 px-3">
                                    <Col className={'px-0  fw-semibold'}>{`Steuer (${tax.taxRate} %)`}</Col>
                                    <Col className="d-flex justify-content-end fw-bold">{formatPrice({ value: tax.tax, locale, currencyId: 'de-CH' === locale ? 'CHF' : 'EUR' })}</Col>
                                </Row>
                            )}

                            {cart?.price.calculatedTaxes.map((tax: any, index: number) =>
                                <Row key={'sidecart_tax_' + index} className="mb-3 offcanvas-row-bottom fs-8 px-3">
                                    <Col className={'px-0  fw-semibold'}>{`Gesamtbetrag`}</Col>
                                    <Col className="d-flex justify-content-end fw-bold">{formatPrice({ value: cart?.price?.totalPrice, locale, currencyId: 'de-CH' === locale ? 'CHF' : 'EUR' })}</Col>
                                </Row>
                            )}

                            {0 < Object.keys(cart?.errors).length && <Row className="mb-3 offcanvas-row-bottom px-3">
                                <hr className={'mt-0'} />
                                <Errors />
                            </Row>
                            }
                            <Row className="mb-3 px-3">
                                <hr className={'mt-0'} />
                                <Button
                                    className={'justify-content-center'}
                                    variant={'secondary'}

                                    onClick={() => {
                                        setShow(false)
                                        router.push('/checkout/cart')
                                    }}
                                >
                                    <span className="d-inline-block  icon  icon--chevron-right  icon--reversed  icon--valign__middle  icon--size__sm  icon--white">
                                        Warenkorb anzeigen
                                    </span>
                                </Button>
                            </Row>
                        </>
                    )}

                    <Row>
                        <ActionBox isBorderless hasIcon={false} hasSpan={false} variant={'secondary'} className={'mh-6  py-2  align-items-start'}>
                            <NewCustomerCouponInfo>
                                <dl className={'row  mb-1'}>
                                    <dt className={'col-1 pe-0'}><small>*</small></dt>
                                    <dd className={'col-11 ps-0  pe-5  mb-0'}>
                                        <small>{t('coupon_info')}</small>
                                    </dd>
                                </dl>
                            </NewCustomerCouponInfo>
                        </ActionBox>
                    </Row>

                </Container>
            </Offcanvas>
        </>
    )
}

export default OffcanvasCart
