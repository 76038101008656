'use client'

import { handleAddPromotionToCart } from "@app/actions";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCartContext } from "@context/cart-provider";
import Cookies from 'js-cookie';
import t, { getErrors } from "@/utils/translation-faker"

const Coupon = () => {
    const searchParams = useSearchParams();
    const coupons = searchParams.getAll('coupon')

    const { cart, setCart } = useCartContext()
    const [addedCoupons, setAddedCoupons] = useState<string[]>([])

    const addPromotionToCart = async (code: string) => {
        await handleAddPromotionToCart([code]).then(({ errors, ...cart }: any) => {

            if (errors) {
                let formattedErrors = getErrors(errors)
                formattedErrors.forEach(error => {
                    toast(error.message, { type: error.type })
                });
            }

            let storedCoupons = Cookies.get('storedCoupons');
            storedCoupons = storedCoupons ? JSON.parse(storedCoupons) : []

            if (Array.isArray(storedCoupons)) {
                let updatedCoupons = storedCoupons.filter((coupon: string) => coupon !== code)
                Cookies.set('storedCoupons', JSON.stringify(updatedCoupons))
            }

            setCart({ ...cart, errors })
        })
    }

    useEffect(() => {
        if (!cart) return;

        let strCouponArr = Array.isArray(coupons) ? coupons : (coupons ? [coupons] : [])

        let storedCoupons = Cookies.get('storedCoupons')
        storedCoupons = storedCoupons ? JSON.parse(storedCoupons) : []

        if (cart?.lineItems?.length === 0) {
            if (!strCouponArr.every(coupon => storedCoupons?.includes(coupon))) {
                strCouponArr.forEach(coupon => {
                    if (Array.isArray(storedCoupons) && !storedCoupons.includes(coupon)) {
                        storedCoupons.push(coupon);
                    }
                });

                Cookies.set('storedCoupons', JSON.stringify(storedCoupons))
                toast(t('promotion-cart-empty'), { type: 'error' })
            }

            return
        }

        if (Array.isArray(storedCoupons) && storedCoupons.length > 0) {
            strCouponArr = Array.from(new Set([...strCouponArr, ...storedCoupons]))
        }

        strCouponArr.filter(coupon => !cart?.extensions?.cartPromotions?.addedCodes?.includes(coupon)).forEach(coupon => {
            if (!addedCoupons.includes(coupon)) {
                addPromotionToCart(coupon)
                setAddedCoupons((addedCoupons) => ([...addedCoupons, coupon]))
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coupons, cart])


    return null
}

export default Coupon
